<template>
  <div>
    <base-table ref="tableRef" :columnsData="columns" rowKey="id" :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">财务账户</h4>
          <a-button class="ml-3" type="primary" @click="handlerEdit('add')" html-type="submit">新增</a-button>
        </div>
      </template>

      <template #operation="{record}">
        <a-button class="p-0" type="link" @click="handlerDetail(record)">查看</a-button>
        <a-button class="p-0 ml-4" v-if="record.is_city==2" type="link" @click="handlerEdit('edit',record)">编辑</a-button>
      </template>
    </base-table>

    <add-modal v-if="isEidtShow" :show.sync="isEidtShow" :id="activeId" :type="type" />
    <detail-modal v-if="isDetailShow" :show.sync="isDetailShow" :id="activeId" />
  </div>
</template>

<script>
import {
  getList,
} from "@/api/system/account.js"
import addModal from './components/add-modal.vue'
import detailModal from './components/detail-modal.vue'

export default {
  components: { addModal, detailModal },
  data() {
    return {
      columns: [
        {
          title: "账号ID",
          dataIndex: "id",
          align: "center",
          width: 80,
        },
        {
          title: "账号名称",
          dataIndex: "name",
          align: "center",
          width: "40%",
        },
        {
          title: "城市财务",
          dataIndex: "is_city",
          align: "center",
          width: 80,
          slots: {
            customRender: "is_city",
          },
          slotsType: "format",
          slotsFunc: (val)=>{ return val==2?"是":"" },
        },
        // {
        //   title: "钱包账户",
        //   dataIndex: "outlets_city",
        //   align: "center",
        // },
        {
          title: "电子发票",
          dataIndex: "support_electronic_invoice",
          align: "center",
          width: 80,
          slots: {
            customRender: "support_electronic_invoice",
          },
          slotsType: "format",
          slotsFunc: (val)=>{ return val==2?"":"支持" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          align: "center",
          width: 140,
        },
      ],

      type: "add",
      activeId: '',
      tableData: [],
      isEidtShow: false,
      isDetailShow: false,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { data, code } = await getList()
      if (code === 0) {
        this.tableData = data.list
      }
    },

    handlerEdit(type, row) {
      this.type = type
      this.activeId = row ? row.id : ''
      this.isEidtShow = true
    },

    handlerDetail(row) {
      this.activeId = row ? row.id : ''
      this.isDetailShow = true
    },
  },
}
</script>

<style>
</style>