<template>
  <div>
    <!-- 详情 modal -->
    <a-modal :visible="show"
             width="600px"
             title="详情"
             footer=""
             @cancel="$emit('update:show', false)">
      <a-form-model ref="addForm"
                    :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 18 }"
                    :model="formData">
        <a-form-model-item prop="name" label="账户名">
          <a-input disabled v-model="formData.name"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="explain" label="说明">
          <a-input style="width:100%" disabled 
                  v-model="formData.explain"></a-input>
        </a-form-model-item>

        <a-form-model-item label="发票"
                         prop="support_electronic_invoice">
          <a-select placeholder="支持哪种发票" disabled
                    v-model="formData.support_electronic_invoice">
            <a-select-option v-for="(item, index) in invoiceList"
                             :key="index"
                             :value="item.value">{{ item.title }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="weixin_merchant_id" label="微信商户号">
           <a-input style="width:100%" disabled 
                  v-model="formData.weixin_merchant_id"></a-input>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getDetail,
  add,
  update
} from "@/api/system/account.js"
import {
  getFinanceCityList
} from "@/api/outlets"
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      formData: {
        name: "",
        explain: "",
        outlets_city_adcode: "",
        support_electronic_invoice: "",
        weixin_merchant_id: "",
      },
      cityList: [],
      invoiceList: [
        {
          value: 1,
          title: "支持电子发票",
        },{
          value: 2,
          title: "不支持电子发票",
        },
      ],
    }
  },
  mounted(){
    this.initData()
  },
  methods: {
    async initData(){
      const res = await getDetail({id: this.id})
      this.formData = res.data
      this.formData.id = this.id
      const { code, data } = await getFinanceCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },
    handlerSave () {
      if (this.type === "add") {
        add(this.formData).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      } else {
        update(this.formData).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      }
    },

    changeCheck(){
      this.formData.vip_card_recharge_account = this.isDefaultRechargeAccount ? 2 : 1
    }
  }
}
</script>

<style>
.recharge-account{
  align-items: center;
  
}
.recharge-account .ant-select{
  flex: 1;
  margin-right: 20px;
}

</style>